<template>
  <div class="edit-order-number">
    <div v-show="editStatus" class="edit-order">
      <el-input
        ref="valueRef"
        v-model="value"
        controls-position="right"
        :min="0"
        style="flex: 1;text-align: center;"
        oninput="value=value.replace(/[^0-9.]/g,'')"
        @change="handleChange"
        @keyup.enter="handleSubmit"
        @focus="handleFocus"
        @blur="handleBlur"
      ></el-input>
      <Check class="el-icon check-icon" @click="handleSubmit" />
      <Close class="el-icon close-icon" @click="close" />
    </div>
    <div v-show="!editStatus" class="order-number-option" @click="open">
      <div class="order-number-text">{{ value }}</div>
      <Edit class="el-icon eidt-icon" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from 'vue'
import { Check, Close, Edit } from '@element-plus/icons-vue'

const props = defineProps({
  modelValue: {
    type: [Number, String],
    require: true
  }
})

const emits = defineEmits(['update:modelValue', 'submit'])

const beforeValue = ref(0)
const value = ref(0)
const valueRef = ref(null)

watch(
  () => props.modelValue,
  newVal => {
    beforeValue.value = newVal
    value.value = newVal
  },
  { immediate: true }
)

const editStatus = ref(false)
const isClickSubmit = ref(false)

const handleChange = val => {
  value.value = parseInt(val)
  emits('update:modelValue', parseInt(val))
}

const handleFocus = e => {}

const handleBlur = e => {
  const timer = setTimeout(() => {
    close()
  }, 100)
}

const handleSubmit = () => {
  isClickSubmit.value = true
  emits('submit', value.value)
  valueRef.value.blur()
}

const open = () => {
  editStatus.value = true
  valueRef.value.focus()
}

const close = type => {
  editStatus.value = false
  if (!isClickSubmit.value) value.value = beforeValue.value
  isClickSubmit.value = false
}
</script>

<style lang="scss" scoped>
.edit-order-number {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.edit-order {
  display: flex;
  align-items: center;
  width: 100%;
}

.order-number-option {
  display: flex;
  align-items: center;
  width: 100%;
}
.order-number-text {
  flex: 1;
  text-align: center;
}

.el-icon {
  margin: 0 5px;
  cursor: pointer;
  z-index: 1;
}
</style>
