<template>
  <el-form
    :model="formData"
    ref="vForm"
    :rules="rules"
    label-position="left"
    label-width="80px"
    size="small"
    @submit.prevent
  >
    <el-row>
      <el-col :span="4" class="grid-cell">
        <el-form-item label="状态" class="label-right-align">
          <el-select v-model="formData.isAlive" clearable placeholder="请选择">
            <el-option
              v-for="item in isAliveOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="2" class="grid-cell button-box">
        <div class="static-content-item">
          <el-button type="primary" @click="search">搜 索</el-button>
        </div>
      </el-col>
      <el-col :span="2" class="grid-cell button-box">
        <div class="static-content-item">
          <el-button @click="clear">清空</el-button>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import _ from 'lodash'

/** 搜索 */
const formData = ref({})
const rules = ref({
  // 搜索框规则
  phone: [
    {
      pattern: /^[1][3-9][0-9]{9}$/,
      trigger: ['blur', 'change'],
      message: '请输入正确手机号'
    }
  ],
  idcard: [
    {
      pattern: /^[A-Za-z0-9]+$/,
      trigger: ['blur', 'change'],
      message: '请输入正确身份证号'
    }
  ]
})

const emits = defineEmits(['clear', 'search'])

// 搜索
const search = () => {
  const data = {
    ...Object.fromEntries(
      Object.entries(formData.value).filter(item => item[1] !== '')
    )
  }
  if (_.isEmpty(data)) {
    emits('search', false)
    return
  }
  emits('search', data)
}

// 清空
const clear = () => {
  formData.value = {}
  emits('clear')
}

/** /搜索 */

const isAliveOption = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 1,
    label: '是'
  },
  {
    value: 0,
    label: '否'
  }
]
</script>

<style lang="scss" scoped>
.el-form {
  margin-bottom: 18px;
}

.button-box {
  width: 85px;
  flex: initial;
}

.el-col-4 {
  max-width: none;
  flex: none;
  margin-right: 40px;
}

::v-deep .el-form-item__label {
  text-align: right;
  width: inherit !important;
}
</style>

<style lang="scss">
.el-input-number.full-width-input,
.el-cascader.full-width-input {
  width: 100% !important;
}

.el-form-item--medium {
  .el-radio {
    line-height: 36px !important;
  }

  .el-rate {
    margin-top: 8px;
  }
}

.el-form-item--small {
  .el-radio {
    line-height: 32px !important;
  }

  .el-rate {
    margin-top: 6px;
  }
}

.el-form-item--mini {
  .el-radio {
    line-height: 28px !important;
  }

  .el-rate {
    margin-top: 4px;
  }
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: var(--el-input-background-color, var(--el-color-white));
  background-image: none;
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  border: var(--el-input-border, var(--el-border-base));
  box-sizing: border-box;
  color: var(--el-input-font-color, var(--el-text-color-regular));
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: var(--el-transition-border);
  width: 100%;
}

.clear-fix:before,
.clear-fix:after {
  display: table;
  content: '';
}

.clear-fix:after {
  clear: both;
}

.float-right {
  float: right;
}
</style>
