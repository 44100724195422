<template>
  <div class="">
    <el-card class="header">
      <search @search="searchClick" @clear="clearClick"></search>
    </el-card>
    <el-card>
      <div class="table-head-box">
        <el-button
          style="float: right"
          :icon="Refresh"
          size="small"
          round
          @click="getPermissionList"
        ></el-button>
        <el-button class="button" size="small" @click="expandTable(true)">
          全部展开
        </el-button>
        <el-button class="button" size="small" @click="expandTable(false)">
          全部收起
        </el-button>
        <el-button
          v-permission="['addPermission']"
          class="button"
          type="primary"
          size="small"
          @click="handleCreate"
        >
          新增
        </el-button>
        <el-button
          v-permission="['deletePermission']"
          class="button"
          type="danger"
          size="small"
          :disabled="!selectedIndexs.length"
          @click="handleAllDelete"
        >
          批量删除
        </el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="allPermission"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :default-expand-all="isExpand"
        :expand-row-keys="expandRowKeys"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" width="200"></el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="url"
          label="地址"
          min-width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="managerUrl"
          label="前端地址"
          width="150"
        ></el-table-column>
        <el-table-column min-width="150">
          <template #header>
            <div style="display: flex;align-items:center;">
              排序
              <Edit class="el-icon" style="margin-left: 5px;" />
            </div>
          </template>
          <template #default="{ row }">
            <editOrderNumber
              :modelValue="row.orderNumber"
              @submit="handleEditOrderNumber($event, row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="菜单类型" width="100">
          <template #default="{ row }">
            <el-tag :type="row.menuType ? '' : 'warning'">
              {{ row.menuType === 1 ? '权限' : '菜单' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="要求权限" width="100">
          <template #default="{ row }">
            <el-tag :type="row.requireAuth ? 'success' : 'danger'">
              {{ row.requireAuth === 1 ? '是' : '否' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template #default="{ row }">
            <el-tag :type="row.isAlive ? 'success' : 'danger'">
              {{ row.isAlive === 1 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          prop="action"
          width="180"
          align="center"
          #default="{ row }"
        >
          <el-button
            v-permission="['editPermission']"
            type="primary"
            size="small"
            @click="handleUpdate(row)"
          >
            编辑
          </el-button>
          <el-button
            v-permission="['deletePermission']"
            type="danger"
            size="small"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </el-table-column>
      </el-table>
    </el-card>

    <create-dialog
      v-model="createDialogVisible"
      :info="editSelectClient"
      :parentIdTreeData="allPermission"
      @updateClient="getPermissionList"
    ></create-dialog>
  </div>
</template>

<script setup>
import {
  permissionList,
  deletePermission,
  updatePermission
} from '@/api/permission'
import { ref, watch, onActivated } from 'vue'
import { dataToTree } from '@/utils/tools.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Edit, Refresh } from '@element-plus/icons-vue'
import search from './components/search.vue'
import CreateDialog from './components/create.vue'
import editOrderNumber from '@/components/editOrderNumber/editOrderNumber.vue'
import dayjs from 'dayjs'

// 数据相关
const multipleTable = ref(null)
const searchFrom = ref({})
const loading = ref(false)
/**
 * 权限分级：
 * 1. 一级权限为页面权限
 * 2. 二级权限为功能权限
 */
// 所有权限
const allPermission = ref([])
const getPermissionList = async () => {
  loading.value = true
  const list = await permissionList({
    ...Object.fromEntries(
      Object.entries(searchFrom.value).filter(
        item => item[1] !== '' || item[1] === 0
      )
    ),
    isDeleted: 0,
    _t: dayjs().valueOf()
  })
  allPermission.value = dataToTree(list)
  loading.value = false
}

onActivated(getPermissionList)

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 新增点击事件
const createDialogVisible = ref(false)
const handleCreate = () => {
  createDialogVisible.value = true
}
// 修改点击事件
const editSelectClient = ref({})
const handleUpdate = row => {
  editSelectClient.value = row
  createDialogVisible.value = true
}

/**
 * 处理删除
 */
const handleDelete = row => {
  ElMessageBox.confirm('确定要删除 ' + row.name + ' 吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deleteFn([row.id])
  })
}
/**
 * 批量删除
 */
const handleAllDelete = () => {
  ElMessageBox.confirm('是否批量删除此信息吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    const ids = selectedIndexs.value.map(item => item.id)
    deleteFn(ids)
  })
}
/**
 * 处理删除请求
 */
const deleteFn = async ids => {
  try {
    await deletePermission(ids)
    ElMessage.success('删除成功')
    // 重新渲染数据
    getPermissionList()
    multipleTable.value.clearSelection()
  } catch (error) {}
}
watch(createDialogVisible, val => {
  if (!val) editSelectClient.value = {}
})

const handleEditOrderNumber = async ($event, row) => {
  loading.value = true
  try {
    const res = await updatePermission({
      ...row,
      orderNumber: $event
    })
    ElMessage.success('更新成功')
  } catch (error) {
    console.log('error :>> ', error)
  } finally {
    loading.value = false
  }
}

// 搜索
const searchClick = e => {
  searchFrom.value = e
  getPermissionList()
}
// 清空
const clearClick = () => {
  searchFrom.value = {}
  getPermissionList()
}

const isExpand = ref(false)
const expandRowKeys = ref([])
/**
 * 处理属性表格展开收起
 * @param {Boolean} isExpandAll 是否展开
 */
const expandTable = isExpandAll => {
  isExpand.value = isExpandAll
  // 展开所有行
  if (isExpand.value) {
    expandRowKeys.value = []
    allPermission.value.forEach(row => {
      if (row.children && row.children.length > 0) {
        expandRowKeys.value.push(row.id)

        row.children.forEach(row2 => {
          if (row2.children && row2.children.length > 0) {
            expandRowKeys.value.push(row2.id)
          }
        })
      }
    })
  } else {
    //  折叠所有行
    expandRowKeys.value = []
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 22px;
}
.pagination {
  margin-top: 20px;
  text-align: center;
}
.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
</style>
