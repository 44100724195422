<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="600px"
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="上级目录" prop="parentId">
          <el-cascader
            placeholder="请选择"
            :options="parentIdTreeDataList"
            filterable
            clearable
            :props="{ checkStrictly: true }"
            v-model="parentIdSelectedOptions"
            style="width: 100%;"
            @change="parentIdHandleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            排序
            <el-tooltip
              class="box-item"
              effect="dark"
              content="数字越小越靠前"
              placement="top-start"
            >
              <QuestionFilled class="el-icon" />
            </el-tooltip>
          </template>
          <el-input-number
            v-model="form.orderNumber"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单类型">
              <el-select v-model="form.menuType" placeholder="请选择">
                <el-option
                  v-for="item in menuTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否要求权限">
              <el-select v-model="form.requireAuth" placeholder="请选择">
                <el-option
                  v-for="item in requireAuthOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item v-if="form.menuType === 1" label="前端地址">
          <el-input v-model="form.managerUrl"></el-input>
        </el-form-item>

        <el-form-item label="启用">
          <el-radio-group v-model="form.isAlive">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue'
import { addPermission, updatePermission } from '@/api/permission'
import { ElMessage } from 'element-plus'
import { QuestionFilled } from '@element-plus/icons-vue'
import _ from 'lodash'

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  },
  parentIdTreeData: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return `${JSON.stringify(props.info) === '{}' ? '创建' : '编辑'}角色`
})

// 数据源
const form = ref({
  isAlive: 1,
  managerUrl: '',
  menuType: 0,
  name: '',
  orderNumber: 0,
  parentId: '',
  requireAuth: 0,
  url: ''
})

const ruleFormRef = ref(null)

// 取消
const closed = () => {
  form.value = {
    isAlive: 1,
    managerUrl: '',
    menuType: 0,
    name: '',
    orderNumber: 0,
    parentId: '',
    requireAuth: 0,
    url: ''
  }
  parentIdSelectedOptions.value = []
  emits('update:modelValue', false)
}

// 提交事件
const submitLoading = ref(false)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      submitLoading.value = true
      let param = _.cloneDeep(form.value)
      if (!form.value.password) {
        param = _.omit(param, ['password'])
      }
      try {
        _.has(param, 'id')
          ? await updatePermission(param)
          : await addPermission(param)
        ElMessage.success('更新成功')
        // emits('updateClient', param)
        emits('updateClient')
        closed()
      } finally {
        submitLoading.value = false
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    const info = JSON.parse(JSON.stringify(props.info))
    form.value = info
    // console.log('info :>> ', info)
    if (!info.parentId || info.parentId === 0) {
      parentIdSelectedOptions.value = [0]
      return
    }
    console.log('不是顶级目录')
    parentIdSelectedOptions.value = info.arrParentId
      .split(',')
      .filter(
        item =>
          item !== null &&
          item !== 'null' &&
          item !== undefined &&
          String(item) !== '0' &&
          String(item) !== String(info.id)
      )
      .map(item => +item)
  } finally {
    loading.value = false
  }
}
watch(
  () => props.info,
  val => {
    if (JSON.stringify(val) !== '{}') getDetail()
  }
)

const parentIdTreeDataList = computed(() => {
  const list = _.cloneDeep(props.parentIdTreeData)
  list.unshift({ id: 0, label: '顶级目录', key: 0, value: 0 })
  return list
})
const parentIdSelectedOptions = ref([])
const parentIdHandleChange = value => {
  if (value && value.length) {
    form.value.parentId = value[value.length - 1]
  }
}

const rules = {
  name: [
    {
      required: true,
      message: '请输入名称',
      trigger: 'blur'
    }
  ],
  parentId: [
    {
      required: true,
      message: '请选择上级目录',
      trigger: 'blur'
    }
  ]
}

const menuTypeOptions = [
  {
    label: '菜单',
    value: 0
  },
  {
    label: '权限',
    value: 1
  }
]

const requireAuthOptions = [
  {
    label: '否',
    value: 0
  },
  {
    label: '是',
    value: 1
  }
]
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  line-height: 178px;
}
::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-success-container {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 178px;
  height: 178px;
  position: relative;
  font-size: 0;

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    border-radius: 6px;
  }
  .del-img {
    position: absolute;
    bottom: 0;
    width: 178px;
    height: 30px;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
}
.photo-container {
  padding-left: 37px;
  .avatar-uploader ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader ::v-deep .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
    line-height: 178px;
  }
  ::v-deep .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar-success-container {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 178px;
    height: 178px;
    position: relative;
    font-size: 0;

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
      border-radius: 6px;
    }
    .del-img {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
      background: rgba(0, 0, 0, 0.2);
      text-align: center;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>
